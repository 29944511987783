import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`

html,body { height:100%; }

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  body {
    font-family: 'Open Sans', sans-serif;
    line-height: 1;
    background: url('assets/img/bg.jpg') no-repeat center center;
    backdrop-filter: blur(6px);
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    width: 100%;
    height: 100vh;
    color: #fff;
    background-color: #11171b;
  }
  body::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    z-index: -1;
  }
  `;

export default GlobalStyle;