import React, { useState } from 'react';
import Countdown from 'react-countdown';
import styled from 'styled-components';
import { FaTwitter, FaInstagram, FaFacebook, FaLinkedin } from 'react-icons/fa';
import validator from 'validator'
import $ from 'jquery';
import emailjs from '@emailjs/browser';
import logoImg from './logo.png'

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  h1 {
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 4px;
    margin-bottom: 4rem;
    margin-top:8px
  }
  p {
    max-width: 600px;
    margin: auto;
    line-height: 1.8;
    margin-bottom: 4rem;
  }
  @media (max-width: 850px) {

    h1 {
      font-size: 2rem;
      margin-bottom: 3rem;
      margin-top:16px
      
    }
    p {
      margin-bottom: 2rem;
      max-width: 400px;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 8px;
  margin: auto;
`;

const TimeWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 700px;
  margin: auto;
  justify-content: space-between;
  margin-bottom: 4rem;
  @media (max-width: 850px) {
    max-width: 400px;
    margin-bottom: 2rem;
  }
`;

const TimeBox = styled.div`
  width: 130px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  h4 {
    font-size: 2.6rem;
  }
  p {
    font-size: 1.2rem;
    margin: 0;
  }
  @media (max-width: 900px) {
    width: 80px;
    height: 80px;
    h4 {
      font-size: 1.4rem;
    }
    p {
      font-size: 0.9rem;
      margin: 0;
    }
  }
`;

const BtnGroup = styled.div`
  max-width: 512px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-columns: 2.5fr 1fr;
  gap: 1rem;
  margin-bottom: 4rem;
  @media (max-width: 850px) {
    max-width: 85%;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.8rem;
    margin-bottom: 2rem;
  }
  @media (max-width: 1024px) {
    max-width: 64%;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.8rem;
    margin-bottom: 2rem;
  }
`;

const TextField = styled.input`
  height: 56px;
  font-size: 17px;
  
  padding: 0.6rem 1rem;
  border-style: none;
  border-radius: 0.25rem;
  margin: 0;
  background-color: rgba(255,255,255,0.64);
  transition: 0.4s all;
  line-height: normal;
  color: white;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  font-weight: 400;
  @media (max-width: 850px) {
    height: 45px;
  }
`;

const Textarea = styled.textarea`
  height: 128px;
  font-size: 17px;
  resize: none;
  overflow:hidden;
  padding: 0.6rem 1rem;
  border-style: none;
  border-radius: 0.25rem;
  margin: 0;
  background-color: rgba(255,255,255,0.64);
  transition: 0.4s all;
  line-height: normal;
  color: white;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  font-weight: 400;
  @media (max-width: 850px) {
    height: 70px;
  }
`;

const Button = styled.button`
  height: 56px;
  padding: 0.6rem 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
  color: #fff;
  cursor: pointer;
  border-radius: 0.25rem;
  background: ${(props) =>
    props.primary ? '#40b04a' : props.outline ? 'none' : 'white'};
  border: ${(props) =>
    props.primary
      ? '1px solid #40b04a'
      : props.outline
      ? '2px solid #fff'
      : 'white'};
  font-weight: 500;
`;

const Social = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 64px;
  margin: auto;
  @media (max-width: 850px) {
    max-width: 64px;
  }
`;

const CopyRight = styled.div`
  display: block;
  justify-content: space-between;
  margin: auto;
  margin-top:32px;
  font-size:smaller;
  text-decoration: none;
`;

const SiteLink = styled.a`
  color: white;
  text-decoration: none;
`;

const Icon = styled.a`
  font-size: 1.4rem;
  color: #fff;
  margin-left:8px;
  &:hover {
    color: #40b04a;
  }
`;

const Logo = styled.img`
display: inline-flex;
justify-content: center;
align-items: center;
width:128px;
margin-bottom:24px;
`;

const App = () => {
 
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');
    

    function sendEmail(e){
      e.preventDefault();

      emailjs.sendForm(
          "service_6eb8kzt",
          "template_zftvir9",
          e.target,
          "OS_tiNnwja9QeV-IJ"
      ).then((res) => {
          if(res){
             alert("email sent successfully")
          }else{
            alert("email not sent")
          }
          
        
      }).catch((e) => {
          alert("There was a problem")
      })
  }

  const Completionist = () => <span>We are online!</span>;
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <Section>
          <Container>
            <Logo src={logoImg}></Logo>
            <h1>Website Under Construction</h1>
            {/* <TimeWrapper>
              <TimeBox>
                <h4>{days}</h4>
                <p>days</p>
              </TimeBox>
              <TimeBox>
                <h4>{hours}</h4>
                <p>hours</p>
              </TimeBox>
              <TimeBox>
                <h4>{minutes}</h4>
                <p>minutes</p>
              </TimeBox>
              <TimeBox>
                <h4>{seconds}</h4>
                <p>seconds</p>
              </TimeBox>
            </TimeWrapper> */}

            {/* <p><b>Storkom</b> Humbly conceived nearly 30 years ago, StorKom are the same renowned Shell Logistics and subsidiaries operation that have served customers in the Kingdoms of Saudi Arabia and Bahrain since the early 90s.</p> */}
            
            <form onSubmit={sendEmail}>
            <BtnGroup>
              <TextField placeholder="Email Address" type="email" name="email" onChange={event => setEmail(event.target.value)} required></TextField>
              <TextField placeholder="Contact Number" type="tel" name="number" onChange={event => setNumber(event.target.value)} required></TextField>
              <Textarea placeholder="Message" name="message" onChange={event => setMessage(event.target.value)} required></Textarea>
              <Button primary type="submit" name="submit" >Send</Button>
            </BtnGroup>
            </form>

            <Social>
              <Icon href='https://www.facebook.com/storkomx'>
                <FaFacebook />
              </Icon>
              <Icon href='https://www.linkedin.com/company/storkom?trk=tyah&trkInfo=clickedVertical%3Acompany%2CentityType%3AentityHistoryName%2CclickedEntityId%3Acompany_company_company_company_company_company_company_company_company_13247378%2Cidx%3A0'>
                <FaLinkedin />
              </Icon>
            </Social>
            <CopyRight>© Copyright 2022. Developed by <SiteLink href='https://maroonfrog.com/' >Maroonfrog</SiteLink>.</CopyRight>
          </Container>
        </Section>
      );
    }
  };

  return (
    // console.log(Date.now());
    <div>
      <Countdown date={1000647765442848 + 1235002555} renderer={renderer}></Countdown>
    </div>
    
  );
  
  }

export default App;